<template>
    <router-view />
</template>
<script>
import { useStore } from "vuex";
import { computed, watch } from "@vue/runtime-core";
export default {
  setup() {
    const store = useStore();
    const saveSingleSignOn = computed(() => store.state.singleSignOn);
    watch(
      () => saveSingleSignOn.value,
      (val) => {
        console.log("请勿点开多个页面！");
      }
    );
    return {
    };
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #292931;
}
* {
  padding: 0;
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
