import { createStore } from "vuex";

export default createStore({
  state: {
    orderList: 0,
    roomList: 0,
    orderUser: 0,
    singleSignOn: 0,
  },
  mutations: {
    // 定义存数据的方法
    // 排队列表
    saveOrderList(state) {
      state.orderList++;
    },
    // 房间列表
    saveRoomList(state) {
      state.roomList++;
    },
    // 订单用户
    saveOrderUser(state) {
      state.orderUser++;
    },
    // 单点登录
    saveSingleSignOn(state) {
      state.singleSignOn++;
    },
  },
  actions: {},
  modules: {},
});
