import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/order",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Order.vue"),
  },
  {
    path: "/addplayers",
    name: "AddPlayers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/AddPlayers.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 设置title
router.beforeEach((to, from, next) => {
  if (to.path !== "/") {
    const assessToken = localStorage.getItem("token");
    if (assessToken) {
      next();
      return;
    } else {
      console.warn("not authenticated");
      next({ path: "/" });

      return;
    }
  } else {
    localStorage.clear();
  }

  // 设置头部
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "电鲸科技";
  }
  next();
});

export default router;
