<template>
  <el-container class="lineUp">
    <el-header class="headTitle">
      <div class="leftTitle">
        <img
          class="leftTitleImg"
          style="width: 100%"
          src="../assets/title.png"
          alt=""
        />
      </div>
      <div class="rigTitle">
        <div class="lef">
          <img class="lefImg" src="../assets/shop.png" alt="" />
          <p class="lefName">{{ shop_name }}</p>
        </div>
        <div class="rig">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <img src="../assets/more.png" alt="" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goOrder"
                  ><img class="Logout" src="../assets/order.png" alt="" />
                  订单</el-dropdown-item
                >
                <el-dropdown-item @click="Logout"
                  ><img
                    class="Logout2 Logout"
                    src="../assets/logout.png"
                    alt=""
                  />登出</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-container class="lowerLeft">
      <el-main
        class="mainInfo"
        :class="isCollapse == true ? 'mainInfoTwo' : 'mainInfo'"
      >
        <div
          :class="isCollapse == true ? 'mainBoxTwo' : 'mainBox'"
          v-for="(item, index) in infoList"
          :key="index"
          :style="
            item.is_first === '1' ? 'background:#ee4367 ' : 'background:#6331f0'
          "
        >
          <div class="mainBoxLef">
            <p>场次{{ translate(index) }}</p>
            <span v-if="item.is_first === '1'" class="bot"
              ><img src="../assets/group1.png" alt=""
            /></span>
            <span v-else class="bot"
              ><img src="../assets/waiting.png" alt=""
            /></span>
          </div>
          <div class="mainBoxInfo">
            <ul class="oneBox">
              <p class="one">场地</p>
              <p>{{ item.space }}</p>
            </ul>
            <div class="secondBox">
              <div class="secondLeft">
                <i class="two">游戏</i>
                <span v-if="item.game_name != undefined">{{
                  item.game_name
                }}</span>
              </div>
              <div class="secondRight">
                <el-image :src="item.game_cover" class="secondBoxImg">
                  <template #error>
                    <img
                      src="../assets/game-icon.png"
                      alt=""
                      class="secondBoxImg"
                    />
                  </template>
                </el-image>
              </div>
            </div>
            <div class="threeBox">
              <i class="two active">
                成员<img src="../assets/phone.png" alt="" class="activeImg" />
              </i>
              <div class="twoBox">
                <dl
                  class="boxList"
                  v-for="(memberItem, index) in item.players"
                  :key="index"
                >
                  <dt class="dtBox">
                    <img
                      v-if="memberItem.avatar != ''"
                      class="dtImgNew"
                      :src="memberItem.avatar"
                    />
                    <img v-else class="dtImg" src="../assets/photo.png" />
                  </dt>
                  <dd class="ddBox">
                    <span class="ddTitle">{{ memberItem.player_name }} </span>
                    <span v-if="memberItem.machineIP != ''"
                      >{{ memberItem.machineIP.split(".")[3] }}号设备
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-aside class="asideInfo" :width="isCollapse == true ? '0px' : '576px'">
        <div class="showTable">
          <div v-if="isNow">
            <div class="showTableHeader">
              <div class="recycleBin">
                <el-button
                  type="text"
                  @click="recycleBin('0')"
                  class="response"
                  :class="status === '0' ? 'active' : ''"
                  >排队列表</el-button
                ><el-button
                  type="text"
                  @click="recycleBin('4')"
                  class="response"
                  :class="status === '4' ? 'active' : ''"
                  >回收站</el-button
                >
              </div>
              <div>
                <el-button
                  @click="refresh(val)"
                  type="text"
                  class="showTableButton"
                  ><img src="../assets/refresh.png" /><span
                    >刷新</span
                  ></el-button
                >
              </div>
            </div>
            <div class="lineUp-search">
              <el-input
                v-if="isNow"
                v-model="roomKeywords"
                placeholder=" 请输入手机号/订单号"
                class="lineUp-search-input el-input__inner"
                @input="searchPlayers"
                maxlength="30"
              >
                <template #prefix>
                  <img
                    src="../assets/frame1.png"
                    alt=""
                    @click="searchPlayers"
                  />
                </template>
              </el-input>
              <span v-if="manager_ver == 0">
                <el-button type="text" class="addPlayer" @click="handleAdd">
                  <img src="../assets/add.png" alt="" />
                  <span>添加玩家</span>
                </el-button>
              </span>
            </div>
          </div>
          <p v-else class="showTableHeader">
            <span>历史排队信息</span><span>{{ today }}</span>
          </p>
          <p>
            <el-row :gutter="20" class="tableRow">
              <el-col :span="4">序号</el-col>
              <el-col :span="5" align="left">订单号/手机号</el-col>
              <el-col :span="8" align="center">微信名</el-col>
              <el-col :span="6" align="center">种类</el-col>
            </el-row>
          </p>

          <el-scrollbar height="58vh">
            <p
              v-for="(item, index) in tableData"
              :key="index"
              class="scrollbar-demo-item"
            >
              <el-row :gutter="20" justify="center">
                <el-col :span="2" class="Num">
                  {{ item.order }}
                </el-col>
                <el-col :span="5" class="phoneRow">
                  <span>**{{ item.tid.slice(-4) }}</span>
                  <span
                    ><img src="../assets/vector.png" alt="" />{{
                      item.handlePhone
                    }}
                  </span>
                </el-col>
                <el-col :span="8" class="Num">
                  <span class="rowImg1" v-if="item.avatar == ''"
                    ><img src="../assets/photo.png"
                  /></span>
                  <span class="rowImg" v-else><img :src="item.avatar" /></span>

                  <span class="rowNum">{{ item.player_name }}</span>
                </el-col>
                <el-col :span="6" align="right" class="typeRow">
                  <div class="typeLeft">
                    <div
                      v-if="item.otype_value == '1' || item.otype_value == '3'"
                      class="times"
                    >
                      <img src="../assets/time.png" alt="" />
                    </div>
                    <div v-if="item.otype_value == '7'" class="qingke">
                      <img src="../assets/qingke.png" alt="" />
                    </div>
                    <div
                      v-else-if="
                        item.otype_value == '2' || item.otype_value == '5'
                      "
                      class="onecard"
                    >
                      <img src="../assets/oncecard.png" alt="" />
                    </div>
                    <p
                      v-show="
                        item.otype_value == '1' || item.otype_value == '3'
                      "
                      class="remaining"
                      v-if="isNow"
                    >
                      剩余时长{{ item.countDown }}
                    </p>
                    <p v-else class="remaining">剩余时长00:00:00</p>
                  </div>
                </el-col>
              </el-row>
            </p>
          </el-scrollbar>
        </div>
        <div class="pBox" @click="onTable">
          <p v-if="isNow" class="history">
            <img src="../assets/three-people.png" />
            <span> 历史排队信息</span>
          </p>
          <p v-else class="history">
            <span> 返回排队信息</span><img src="../assets/three-people.png" />
          </p>
        </div>
      </el-aside>
      <div @click="onCollapse">
        <i class="stowButton">
          <img src="../assets/shouqi.png" alt="" v-if="isCollapse" />
          <img src="../assets/zhankai.png" alt="" v-else
        /></i>
      </div>
    </el-container>
  </el-container>
  <el-dialog
    v-model="orderDialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    custom-class="dialog"
    center
    destroy-on-close
    :title="currentTitle"
  >
    <AddPlayers @Dialog="clickDia"></AddPlayers>
  </el-dialog>
</template>

<script>
import "../assets/css/unified.css";
import { useStore } from "vuex";
import {
  computed,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
// 发送数据
import { routeLocationKey, useRouter } from "vue-router";
import service from "@/utils/api";
import moment from "moment";
import SocketService from "../utils/websocket";
import method from "../utils/method";
import { ElMessage } from "element-plus";
import AddPlayers from "../components/AddPlayers.vue";
export default {
  name: "Home",
  components: {
    AddPlayers,
  },
  setup() {
    // Todo 方法
    const SF = SocketService.Instance;
    const router = useRouter();
    const state = reactive({
      orderData: [],
      tid: "",
      translate: method.toChinese,
      timer: null,
      server_time: "",
      //判断是否有赞店铺
      manager_ver: "",
      // 服务开启时间
      service_start_time: "",
      // 服务到期时间
      service_duration: "",
      currentTime: "",
      today: moment().format("YYYY-MM-DD"),
      userId: undefined,
      tableData: [],
      isCollapse: false,
      isNow: true,
      table: [],
      infoList: [],
      status: "0",
      shop_name: "",
      fixedTime: "",
      countDown: "",
      currentTitle: "",
      lineUpPlayers: [],
      roomKeywords: "",
      playersiId: "",
      // 弹出框
      orderDialog: false,
      shop_id: "",
    });
    const store = useStore();
    // 第三步  引入
    const getOrderList = computed(() => store.state.orderList);
    const saveRoomList = computed(() => store.state.roomList);
    const saveOrderUser = computed(() => store.state.orderUser);

    // 第五步 监听
    watch(
      () => getOrderList.value,
      (val) => {
        // 调用接口刷新数据
        getPlayers(state.status);
      }
    );
    watch(
      () => saveRoomList.value,
      (val) => {
        // 调用接口刷新数据
        // console.log("房间已更新");
        getRoom();
        getPlayers(state.status);
        // getRoomPlayersFun(state.playersiId);
      }
    );
    watch(
      () => saveOrderUser.value,
      (val) => {
        // 调用接口刷新数据
        console.log("房间已更新");
        getRoom();
        getPlayers(state.status);
      }
    );
    // 模糊查询玩家
    const searchPlayers = () => {
      if (state.roomKeywords === "" || state.roomKeywords == null) {
        state.tableData = state.lineUpPlayers;
        return;
      }
      let search = state.roomKeywords;
      let list = state.lineUpPlayers.filter(function (item) {
        let searchField = {
          tid: item.tid,
          phone: item.phone,
        };
        return Object.keys(searchField).some(function (key) {
          return String(item[key]).indexOf(search) > -1;
        });
      });
      state.tableData = list;
    };
    //房间列表
    const getRoom = () => {
      state.userId = localStorage.getItem("userId");
      const params = {
        uid: state.userId,
      };
      let oldLength = state.infoList.length;
      service.roomList(params).then((res) => {
        if (res.data.errcode == 0) {
          if (res.data.data < oldLength) {
            state.orderDialog = false;
          }
          state.infoList = res.data.data;
        }
      });
    };
    // 排队列表
    const getPlayers = (val) => {
      service.playersList({ status: val }).then((res) => {
        if (res.data.errcode == 0) {
          state.server_time = res.data.server_time;
          let newData = res.data.data.map((item) => ({
            countDownAll: method.generateCountDown(item, state.server_time),
            handlePhone: item.phone
              ? item.phone.substr(0, 3) + "****" + item.phone.substr(7)
              : "无",
            ...item,
          }));
          if (state.roomKeywords === "") {
            state.tableData = newData;
            state.lineUpPlayers = newData;
          } else {
            let list = newData.filter(function (item) {
              let searchField = {
                tid: item.tid,
                phone: item.phone,
              };
              return Object.keys(searchField).some(function (key) {
                return String(item[key]).indexOf(state.roomKeywords) > -1;
              });
            });
            state.tableData = list;
          }
        }
      });
    };
    const refresh = (val) => {
      getPlayers(state.status);
      ElMessage.closeAll();
      ElMessage.success("刷新成功");
    };
    // 跳转订单
    const goOrder = () => {
      router.push({ path: "/order" });
    };
    // 展开收起
    const onCollapse = () => {
      state.isCollapse = !state.isCollapse;
    };
    // 切换排队列表
    const onTable = () => {
      if (state.isNow) {
        state.isNow = false;
        state.status = "3";
        getPlayers("3");
        state.roomKeywords = "";
      } else {
        state.isNow = true;
        state.status = "0";
        getPlayers("0");
      }
    };
    //切换回收站
    const recycleBin = (val) => {
      state.status = val;
      getPlayers(val);
      if ((val = "4")) {
        state.roomKeywords = "";
      }
    };
    // 退出登录
    const Logout = () => {
      localStorage.clear();
      router.push({ path: "/" });
    };
    // 添加玩家
    const handleAdd = () => {
      state.orderDialog = true;
    };
    const clickDia = (data) => {
      if (data == "false") {
        state.orderDialog = false;
        getPlayers(state.status);
      }
    };
    onMounted(() => {
      getRoom();
      getPlayers("0");
      method.toChinese();
      state.shop_name = localStorage.getItem("shop_name");
      state.manager_ver = localStorage.getItem("manager_ver");

      if (!SF.connected) {
        SocketService.Instance.connect();
      }
      // 倒计时
      state.timer = setInterval(() => {
        if (state.tableData.length > 0) {
          let dirty = false;
          state.tableData.forEach((element) => {
            if (element.countDownAll > 0) {
              element.countDownAll = element.countDownAll - 1;
              element.countDown = method.changeCountDown(element.countDownAll);
              if (element.countDown == "00:00:00") {
                dirty = true;
              }
            } else {
              element.countDown = element.service_duration + "分钟";
              state.timer = null;
            }
          });
          if (dirty == true) {
            getPlayers(state.status);
          }
        }
      }, 1000);
    });
    return {
      ...toRefs(state),
      onCollapse,
      onTable,
      goOrder,
      Logout,
      refresh,
      handleAdd,
      searchPlayers,
      recycleBin,
      clickDia,
    };
  },
};
</script>
<style scoped lang="scss">
.lineUp {
  background-color: #24242b;
  padding: 0px 2vw;
  height: 100vh;
  overflow: hidden;
  // 顶部
  .headTitle {
    color: aliceblue;
    height: 5vh;
    margin: 4vh 0 12px 0;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    .leftTitle {
      width: 20vw;
      .leftTitleImg {
        width: 100%;
        margin-left: -30px;
      }
    }
    .lineUp .headTitle .rigTitle .rig[data-v-4492669c]  {
      width: 100px;
      height: 48px;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: transparent !important;
      margin-top: 8px;
    }
    .rigTitle {
      width: 590px;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      position: relative;
      left: 30px;
      .lef {
        display: flex;
        width: 400px;
        align-items: center;
        .lefImg {
          width: 52px;
          height: 52px;
          position: relative;
          bottom: 12px;
          margin-right: 10px;
        }
        .lefName {
          color: rgb(163, 163, 163);
        }
      }
      .rig {
        width: 100px;
        height: 48px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: transparent !important;
        margin-top: 8px;
        position: relative;
        left: 20px;
        .el-dropdown-link {
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  // 下部
  .lowerLeft {
    color: white;
    display: flex;
    // 下左
    .mainInfo {
      background-color: #292931 !important;
      border-radius: 20px;
      margin-right: 2vw;
      padding: 2.4vh 1.2vw;
      height: 89.15vh;
      overflow: hidden;
      overflow-y: auto;
      .mainBox {
        width: 100%;
        height: 290px;
        border-radius: 10px 30px 30px 10px;
        opacity: 1;
        margin-top: 1.7vh;
        display: flex;
        justify-content: space-between;
        .mainBoxInfo {
          width: 100%;
          height: 100%;
          background: #32323b;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          .oneBox {
            width: 160px !important;
            text-align: center;
            box-sizing: border-box;
            padding: 32px 32px 0px 30px;
            .one {
              width: 98px;
              height: 37px;
              line-height: 37px;
              font-size: 18px;
              border-radius: 4px;
              background-color: #3e3e45;
              margin-bottom: 17px;
            }
          }
          .secondBox {
            width: 300px !important;
            text-align: left;
            span {
              display: inline-block;
              width: 160px;
              line-height: 20px;
              position: relative;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-wrap: break-word;
              word-break: break-all;
            }
            .secondBoxImg {
              width: 299px;
              height: 168px;
              border-radius: 10px 10px 10px 10px;
            }
          }
          .two {
            width: 98px;
            height: 37px;
            line-height: 37px;
            font-size: 18px;
            border-radius: 4px;
            margin: 32px 20px 20px 0px;
            background-color: #3e3e45;
            font-style: normal;
            display: inline-block;
            text-align: center;
          }
          .threeBox {
            width: 575px !important;
            box-sizing: border-box;
            padding: 0px 29px 0px 32px;
            text-align: left;
            .twoBox {
              display: flex;
              display: -webkit-flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: start;
              overflow: auto;
              height: 168px;
              overflow-y: scroll;
              .boxList {
                width: 158px;
                height: 52px;
                display: inline-block;
                text-align: center;
                display: flex;
                // justify-content: space-between;
                margin-right: 12px;
                margin-bottom: 20px;
                .dtBox {
                  font-size: 14px;
                  width: 52px;
                  height: 52px;
                  border-radius: 4px;
                  background-color: aliceblue !important;
                  .dtImg {
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                  }
                  .dtImgNew {
                    width: 52px;
                    height: 52px;
                    border-radius: 4px;
                  }
                }
                .ddBox {
                  width: 98px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  justify-content: space-between;
                  margin-left: 6px;
                  padding: 2px 0;
                  .ddTitle {
                    color: #8d8d99;
                    width: 98px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
      .mainBoxLef {
        width: 170px !important;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 30px 0;
        text-align: center;
        font-size: 24px;
        border-radius: 10px 0px 0px 10px;
        .bot {
          display: inline-block;
          margin-top: 20px;
          img {
            width: 36px;
            height: 126px;
          }
        }
      }
      // 展开下左
      .mainBoxTwo {
        width: 100%;
        height: 254px;
        margin-right: 0vw;
        border-radius: 10px 30px 30px 10px;
        margin: 16px 5px 5px 0px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .mainBoxLef {
          width: 170px !important;
          overflow: hidden;
          box-sizing: border-box;
          margin: 0 auto;
          padding: 30px 0;
          text-align: center;
          font-size: 24px;
          border-radius: 10px 0px 0px 10px;
          .bot {
            display: inline-block;
            margin-top: 20px;
          }
        }
        .mainBoxInfo {
          width: 100%;
          height: 100%;
          background: #32323b;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          .oneBox {
            width: 200px !important;
            text-align: center;
            .one {
              width: 98px;
              height: 37px;
              line-height: 37px;
              font-size: 18px;
              border-radius: 4px;
              margin: 32px 50px 20px 50px;
              background-color: #3e3e45;
            }
          }
          .two {
            width: 98px;
            height: 37px;
            line-height: 37px;
            font-size: 18px;
            border-radius: 4px;
            margin: 32px 20px 20px 0px;
            background-color: #3e3e45;
            font-style: normal;
            display: inline-block;
            text-align: center;
          }
          .secondBox {
            width: 465px !important;
            text-align: left;
            display: flex;
            .secondLeft {
              span {
                display: block;
                width: 100px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                word-wrap: break-word;
                word-break: break-all;
                -webkit-box-orient: vertical;
              }
            }
            .secondRight {
              .secondBoxImg {
                display: inline-block;
                width: 339px;
                height: 190px;
                border-radius: 10px;
                position: relative;
                top: 32px;
              }
            }
          }
          .threeBox {
            width: 920px !important;
            min-width: none;
            box-sizing: border-box;
            padding-left: 52px;
            text-align: left;
            display: flex;
            .twoBox {
              width: 690px;
              display: flex;
              display: -webkit-flex;
              flex-wrap: wrap;
              overflow: auto;
              padding: 32px 13px;
              .boxList {
                width: 158px;
                height: 52px;
                margin-bottom: 14px;
                display: inline-block;
                margin-right: 14px;
                display: flex;
                flex-direction: row;
                text-align: left;
                .dtBox {
                  width: 52px;
                  height: 52px;
                  border-radius: 4px;
                  background-color: whitesmoke;
                  .dtImgNew {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                  }
                  .dtImg {
                    height: 100%;
                  }
                }
                .ddBox {
                  width: 98px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  justify-content: space-between;
                  padding: 2px 0;
                  margin-left: 6px;

                  .ddTitle {
                    color: #8d8d99;
                    width: 98px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
      .active {
        // cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .activeImg {
          width: 20px;
          height: 20px;
          margin-left: 6px;
          position: relative;
          top: 3px;
        }
      }
    }
    // 展开下左
    .mainInfoTwo {
      margin-right: 0vw;
    }
    .twoBox::-webkit-scrollbar {
      width: 0;
    }
    .mainBoxTwo,
    .mainInfo::-webkit-scrollbar {
      width: 0px;
      background: gray;
    }
    // 右下
    .asideInfo {
      height: 87vh;
      overflow: hidden;
      background-color: #292931 !important;
      border-radius: 20px;
      text-align: left;
      background: url("../assets/logo1.png") no-repeat 50% 80%;
      box-sizing: border-box;
      position: relative;
      .showTable {
        .showTableHeader {
          width: 515px;
          position: relative;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          margin-top: 24px;
          .showTableButton {
            background-color: #32323b;
            width: 100px;
            height: 48px !important;
            margin: auto 0;
            position: relative;
            top: -12px;
            text-align: center;
            cursor: pointer;
            span {
              box-sizing: border-box;
              position: relative;
              top: -8px;
              font-size: 14px;
              left: 2px;
            }
            img {
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
        }
        .scollBox {
          padding-bottom: 150px !important;
        }
        .scollBox::-webkit-scrollbar {
          width: 0;
        }
        .lineUp-search {
          width: 514px;
          height: 48px;
          margin: 0 auto;
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          .lineUp-search-input {
            width: 100%;
            height: 48px !important;
            line-height: 48px;
            img {
              position: relative;
              width: 20px;
              height: 20px;
              top: 5px;
              left: 10px;
            }
          }
          .addPlayer {
            background-color: #32323b;
            width: 128px;
            margin-left: 20px;
            height: 48px !important;
            img {
              position: relative;
              width: 24px;
              height: 24px;
              left: -3px;
            }
            span {
              position: relative;
              top: -6px;
              left: 3px;
            }
          }
        }
        .scollBox {
          height: 500px;
          overflow-y: scroll;
        }
      }
      p {
        margin: 10px 30px;
        font-size: 20px;
      }
      .tableRow {
        color: grey;
        font-size: 14px;
        border-bottom: 1px solid #32323b;
        padding-bottom: 10px;
        text-align: center;
        margin-top: 13px;
      }
      .scrollbar-demo-item {
        width: 515px;
        font-size: 14px;
        height: 66px;
        margin-bottom: 12px auto;
        background-color: #32323b;
        border-radius: 15px;
        text-align: center;
        overflow: hidden !important;
        .Num {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          .rowImg {
            img {
              width: 35px;
              height: 35px;
              border-radius: 4px;
            }
          }
          .rowImg1 {
            display: inline-block;
            width: 35px;
            height: 35px;
            padding: 1px;
            background-color: whitesmoke;
            border-radius: 4px;
            img {
              width: 35px;
              height: 35px;
              border-radius: 50%;
            }
          }
          .rowNum {
            margin-left: 10px;
          }
        }
        .phoneRow {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          text-align: left;
          span {
            line-height: 23px;
            img {
              margin-right: 4px;
              width: 8px;
              height: 12px;
            }
          }
        }
        .typeRow {
          width: 140px;
          height: 66px;
          display: flex;
          flex-direction: row;
          padding: 0 !important;
          .typeLeft {
            width: 98px;
            box-sizing: border-box;
            padding: 10px 0;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .times {
              img {
                width: 75px;
                height: 22px;
              }
            }
            .qingke {
              margin-top: 12px;
              img {
                width: 82px;
                height: 20px;
              }
            }
            .onecard {
              height: 100%;
              padding-right: 20%;
              padding-top: 15%;
              img {
                width: 45px;
                height: 15px;
              }
            }
            .remaining {
              width: 100%;
              font-size: 10px;
              margin: 0 auto;
              // background-color: antiquewhite;
            }
          }
        }
      }
      .scrollbar-demo-item:last-child {
        margin-bottom: 110px !important;
      }
      .secondaryCard {
        font-weight: 900;
        overflow: hidden;
        margin: auto auto;
      }
      .recycleBin {
        height: 24px;
        cursor: pointer;
        position: relative;
        top: -4px;

        img {
          height: 100%;
          position: relative;
          top: 4px;
        }
        .imgCard {
        }
        .response {
          font-size: 20px;
          color: grey !important;
        }
        .active {
          color: #fff !important;
        }
      }
      .pBox {
        width: 515px;
        margin: 0 auto;
        height: 48px;
        line-height: 48px;
        background-color: #32323b;
        border-radius: 10px;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 30px;
        .history {
          height: 100%;
          cursor: pointer;
          overflow: hidden;
          margin: 0px 0px;
          // 解决点击出现文字背景高光问题
          -moz-user-select: none; /*火狐*/
          -webkit-user-select: none; /*webkit浏览器*/
          -ms-user-select: none; /*IE10*/
          -khtml-user-select: none; /*早期浏览器*/
          user-select: none;
          span {
            display: inline-block;
            position: relative;
            bottom: 20px;
            font-size: 18px;
          }
          img {
            margin: 12px;
            height: 28px;
            width: 28px;
          }
        }
      }
    }
    //侧边收缩按钮
    .stowButton {
      width: 20px;
      height: 40px;
      position: fixed;
      padding: 10px 4px;
      border-radius: 8px 0px 0px 8px;
      right: 0px;
      background-color: #292931;
      top: 46vh;
      img {
        position: relative;
        top: 10px;
      }
    }
  }
}
// 弹出框
.dialog {
  .dialogTitle {
    padding: 10px !important;
    height: 50px;
    margin-bottom: 38px;
    font-size: 36px;
    color: whitesmoke;
    display: flex;
    justify-content: space-between;
    .dialogTextLeft {
    }
    .dialogTextRight {
      background-color: #52525e;
      width: 156px;
      height: 48px;
      img {
        position: relative;
        left: -2px;
      }
      span {
        position: relative;
        top: -6px;
        left: 4px;
      }
    }
  }
}
// 登出下拉菜单
.el-dropdown__popper .el-dropdown-menu {
  border: none;
  background-color: #292931;
  height: 96px;
  width: 100px;
  line-height: 48px;
  align-content: center;
  align-items: center;
}
.el-dropdown-menu__item {
  list-style: none;
  line-height: 48px;
  font-size: var(--el-font-size-base);
  color: var(--el-text-color-regular);
  cursor: pointer;
  outline: 0;
}
.el-dropdown-menu {
  position: relative;
  top: 0;
  left: 0;
  z-index: var(--el-dropdown-menu-index);
  padding: 0px 0;
  margin: 0;
  background-color: #fff;
  border: none;
  box-shadow: none;
}
.el-dropdown__popper.el-popper[role="tooltip"][data-popper-placement^="bottom"]
  .el-popper__arrow::before {
  background: #292931 !important;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.el-dropdown-menu__item:hover {
  background-color: #32323b !important;
  color: #ebebeb !important;
}
.Logout {
  position: relative;
  top: 6px;
  left: -5px;
}
.Logout2 {
  padding-right: 4px;
}
.lineUp .lowerLeft .asideInfo p[data-v-fae5bece] {
  margin: 0px 30px;
  margin-bottom: 12px;
}
</style>
