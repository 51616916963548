const Base64 = require("js-base64").Base64;
import store from "../store/index.js";
export default class SocketService {
  // 初始化  创建单例
  static instance = null;
  static get Instance() {
    if (!this.instance) {
      this.instance = new SocketService();
    }
    return this.instance;
  }
  // 和服务端连接的socket对象
  ws = null;
  // 存储回调函数
  callBackMapping = {};
  // 标识是否连接成功
  connected = false;
  // 记录重试的次数
  sendRetryCount = 0;
  // 重新连接尝试的次数
  connectRetryCount = 0;
  //  定义连接服务器的方法
  connect() {
    // 连接服务器
    if (!window.WebSocket) {
      return console.log("您的浏览器不支持WebSocket");
    }
    // let token = $.cookie('123');
    let userId = localStorage.getItem("userId");
    // WebSocket连接的地址
    if (userId !== null) {
      let baseUrl = process.env.VUE_APP_API_WEBSOCKET_BASE_URL;
      this.ws = new WebSocket(`${baseUrl}?type=oweb&user_id=${userId}`);
      // console.log(this.ws);
      // 连接成功的事件
      this.ws.onopen = () => {
        console.log("WEB---NODE----连接服务端成功了");
        this.connected = true;
        // 重置重新连接的次数
        this.connectRetryCount = 0;
      };
      // 1.连接服务端失败
      // 2.当连接成功之后, 服务器关闭的情况
      this.ws.onclose = () => {
        console.log("WEB---NODE----连接服务端失败");
        this.connected = false;
        this.connectRetryCount++;
        // store.commit("saveWebSocketData", "fail");
        setTimeout(() => {
          this.connect();
        }, 5000 * this.connectRetryCount);
      };
      // 得到服务端发送过来的数据
      this.ws.onmessage = (msg) => {
        console.log("从服务端获取到了原始数据", msg.data);
        const result = JSON.parse(Base64.decode(msg.data));
        console.log("从服务端获取到了数据", result);

        switch (result.code) {
          // 第二步 存入数据
          // 排队刷新指令
          case "ORDERS_RN":
            store.commit("saveOrderList", "0");
            break;
          // 房间列表指令
          case "REFRESH_ROOMS_N":
            store.commit("saveRoomList", "0");
            break;
          // 订单用户
          case "ORDER_PLAYERS_RN":
            store.commit("saveOrderUser", "0");
            break;
          // 单页面
          case "OTHER_LOGIN_N":
            store.commit("saveSingleSignOn", "0");
            break;
          default:
            break;
        }
      };
    }
  }
  // 回调函数的注册
  registerCallBack(socketType, callBack) {
    this.callBackMapping[socketType] = callBack;
  }
  // 取消某一个回调函数
  unRegisterCallBack(socketType) {
    this.callBackMapping[socketType] = null;
  }
  // 给后台 发送数据的方法
  send(data) {
    console.log("to_server_api", data);
    // 判断此时此刻有没有连接成功
    if (this.connected) {
      this.sendRetryCount = 0;
      try {
        this.ws.send(JSON.stringify(data));
      } catch (e) {
        this.ws.send(data);
      }
    }
  }
}
