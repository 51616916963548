/**
 *  HTTP 服务接口
 */

import service from "../utils/request";
const orgId = "2nXHXwa73x9dQqfhbMNM8Q";
// 登录
function login(params) {
  return service.post("/auth/v1/login/order", params);
}
// 数据存储localStorage
function localStorageSave(save) {
  localStorage.setItem("token", save.accessToken);
  localStorage.setItem("userId", save.accountId);
  localStorage.setItem("shopId", save.shop_id);
  localStorage.setItem("orgId", save.orgId);
  localStorage.setItem("shop_name", save.shop_name);
  localStorage.setItem("manager_ver", save.manager_ver);
}
// 订单列表
function ordersList(params) {
  return service.get("/admin/v1/orders/list", {
    params,
  });
}
// 场次列表
function roomList(params) {
  return service.get(`/admin/v1/organizations/${orgId}/net_rooms/detaillist`, {
    params,
  });
}
// 排队用户
function playersList(params) {
  return service.get(
    `/admin/v1/organizations/${orgId}/net_rooms/shop/players`,
    {
      params,
    }
  );
}
// 订单玩家列表
function orderPlayers(params) {
  return service.get(
    `/admin/v1/organizations/${orgId}/net_rooms/order/players`,
    {
      params,
    }
  );
}
// 解绑扫码玩家
function playersUnbind(params) {
  return service.get(
    `/admin/v1/organizations/${orgId}/net_rooms/players/unbind`,
    {
      params,
    }
  );
}
// 房间玩家成员信息
export function getRoomPlayers(roomId) {
  return service.get(
    `/admin/v1/organizations/${orgId}/net_rooms/players?room_id=${roomId}`
  );
}

// 生成订单
function createOrders(params) {
  return service.post(`/manager/v2/orders/create`, params);
}
//订单加人
function createPlayer(params) {
  return service.post(`/manager/v2/orders/players/create`, params);
}
//订单下玩家列表
export function getPlayers(order_id) {
  return service.get(`/manager/v2/orders/players/list?order_id=${order_id}`);
}
// 删除玩家
export function deletePlayers(player_id) {
  return service.delete(
    `/manager/v2/orders/players/remove?player_id=${player_id}`
  );
}
// 加入排队
function createOrdersJoin(params) {
  return service.post(`/manager/v2/orders/join`, params);
}
export default {
  login,
  ordersList,
  roomList,
  playersList,
  orderPlayers,
  localStorageSave,
  playersUnbind,
  getRoomPlayers,
  createOrders,
  createPlayer,
  getPlayers,
  deletePlayers,
  createOrdersJoin,
};
