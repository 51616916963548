import SocketService from "../utils/websocket";
import moment from "moment";

function socketServe() {
  SocketService.Instance.connect();
}
// 数字转汉字
function toChinese(val) {
  let chin_list = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]; //所有的数值对应的汉字
  let sn = parseInt(val) + 1; //这里由于我的后台是从0开始排序
  if (sn <= 10) {
    return chin_list[sn - 1];
  } else if (sn <= 100) {
    if (sn < 20) return "十" + chin_list[(sn % 10) - 1];
    if (sn % 10 == 0) return chin_list[Math.floor(sn / 10) - 1] + "十";
    else
      return (
        chin_list[Math.floor(sn / 10) - 1] + "十" + chin_list[(sn % 10) - 1]
      );
  } else {
    //可以支持更多
  }
}
// 倒计时
function generateCountDown(val, server_time) {
  if (val.service_duration !== "" && val.service_start_time !== undefined) {
    const sTime = moment(server_time).format("YYYY-MM-DD HH:mm:ss");
    const eTime = moment(Number(val.service_start_time)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let m2 = moment(sTime);
    let m1 = moment(eTime);
    return Number(val.service_duration) * 60 - m2.diff(m1, "second");
  }
}
function changeCountDown(value) {
  var du = moment.duration(value * 1000, "ms");
  let hours = "";
  let minute = "";
  let second = "";

  if (value >= 0) {
    hours = du.get("hours");
    minute = du.get("minutes");
    second = du.get("seconds");
  } else {
    hours = "00";
    minute = "00";
    second = "00";
  }
  return (
    formatNumber(hours) +
    ":" +
    formatNumber(minute) +
    ":" +
    formatNumber(second)
  );
}
function formatNumber(n) {
  n = n.toString();
  return n[1] ? n : "0" + n;
}

export default {
  socketServe,
  toChinese,
  generateCountDown,
  changeCountDown,
  formatNumber,
};
