<template>
  <div>
    <el-header class="dialogTitle">
      <span class="dialogTextLeft">订单号:{{ codeNum }}</span>
      <el-button
        type="text"
        class="dialogTextRight"
        @click="addPlayer"
        disabled
        v-if="codeNum == ''"
      >
        <img src="../assets/add.png" alt="" />
        <span>添加临时玩家</span></el-button
      >
      <el-button type="text" class="dialogTextRight" @click="addPlayer" v-else>
        <img src="../assets/add.png" alt="" />
        <span>添加临时玩家</span></el-button
      >
    </el-header>
    <div class="dialogImg">
      <div class="QRcode">
        <div class="QRimg" element-loading-background="transparent">
          <div v-if="vxImg"><img :src="vxImg" /></div>
          <div v-else v-loading="loading"></div>
        </div>
        <p class="bottomQR">微信扫码加入战斗</p>
      </div>
      <div class="playersTable">
        <el-table
          :data="orderData"
          class="dialogTable"
          height="380"
          style="color: #ebebeb"
          ref="scrollTable"
        >
          <el-table-column
            width="70px"
            prop="index"
            label="序号"
            align="center"
          />
          <el-table-column
            width="200px"
            prop="avatar player_name"
            label="玩家名"
          >
            <template #default="scope">
              <div v-if="scope.row.avatar != ''" class="nameBox">
                <img :src="scope.row.avatar" class="nameImg" />
                <span class="nameUser">{{ scope.row.player_name }}</span>
              </div>
              <div v-else class="nameBox">
                <img src="../assets/photo.png" class="nameImg" />
                <span class="nameUser">{{ scope.row.player_name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="80px"
            prop="status"
            label="操作"
            align="center"
          >
            <template #default="scope">
              <el-button
                type="text
              "
                class="clearOrder"
                @click="remove(scope.row.id)"
              >
                清除<img src="../assets/clear2.png" />
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="playersBottom">
          <p class="typeBox">设置类型：</p>
          <el-form ref="form" :model="formList" class="typeInfo">
            <el-radio-group v-model="formList.orderType">
              <el-radio
                v-for="(item, index) in list"
                :key="index"
                :label="item.label"
                @change="checkStatus(item)"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
            <el-select
              v-model="duration"
              placeholder=""
              class="timeSelect"
              :disabled="prohibit"
              @change="checkDuration"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="timeSelectInner"
                >{{ item.value }}分钟
              </el-option>
            </el-select>
            <el-button
              type="text"
              class="determine"
              @click="joining"
              :disabled="clickDisabled"
              :loading="clickloading"
            >
              确定加入</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import {
  reactive,
  toRefs,
  onMounted,
  computed,
  watch,
} from "@vue/runtime-core";
import { ref, nextTick } from "vue";
import service from "../utils/api";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

export default {
  name: "Home",
  setup(props, context) {
    const form = ref(null);
    const scrollTable = ref(null);
    const state = reactive({
      clickDisabled: false,
      clickloading: false,
      orderDialog: false,
      loading: true,
      shop_id: "",
      vxOrder: "",
      codeNum: "",
      vxImg: "",
      orderData: [],
      checked: "1",
      prohibit: true,
      formList: { orderType: ref("5"), service_duration: "15" },
      list: [
        { name: "单次", label: "5", service_duration: "" },
        { name: "时长", label: "1", service_duration: "60" },
      ],
      duration: "",
      options: ref([
        {
          value: "60",
          label: "60分钟",
        },
        {
          value: "120",
          label: "120分钟",
        },
        {
          value: "180",
          label: "180分钟",
        },
        {
          value: "240",
          label: "240分钟",
        },
        {
          value: "300",
          label: "300分钟",
        },
      ]),
    });
    const store = useStore();
    // 第三步  引入
    const getOrderUser = computed(() => store.state.orderUser);
    // 监听
    watch(
      () => getOrderUser.value,
      (val) => {
        getOrderList();
        // 调用接口刷新数据
      }
    );
    // 添加玩家
    const handleAdd = () => {
      const params = {
        shop_id: state.shop_id,
      };
      service.createOrders(params).then((res) => {
        if (res.data.errcode == 0) {
          var oid = res.data.data.oid;
          var start = oid.substring(0, 2);
          var end = oid.substring(oid.length - 6);
          state.codeNum = start + "***" + end;
          state.vxOrder = res.data.data.oid;
          state.vxImg = res.data.data.qrcode;
        } else {
          ElMessage.error("添加失败");
        }
      });
    };
    // 添加临时玩家
    const addPlayer = () => {
      const params = {
        order_id: state.vxOrder,
      };
      service.createPlayer(params).then((res) => {
        if (res.data.errcode == 0) {
          ElMessage.closeAll();
          ElMessage.success("添加成功");
          getOrderList();
        } else {
          ElMessage.error("添加失败");
        }
      });
    };
    // 获取数据
    const getOrderList = async () => {
      service.getPlayers(state.vxOrder).then((res) => {
        if (res.data.errcode == 0) {
          state.orderData = res.data.data.map((item, index) => {
            return {
              index: index + 1,
              id: item.id,
              avatar: item.avatar,
              player_name: item.player_name,
            };
          });
          setTimeout(() => {
            scrollTable.value._.refs.bodyWrapper.scrollTop =
              scrollTable.value._.refs.bodyWrapper.scrollHeight;
          }, 500);
        }
      });
    };
    // 删除玩家
    const remove = (id) => {
      service.deletePlayers(id).then((res) => {
        if (res.data.errcode == 0) {
          ElMessage.closeAll();
          ElMessage.success("删除成功");
          getOrderList();
        } else {
          ElMessage.error("删除失败");
        }
      });
      //根据order找出索引，
      //如果找到索引直接调用数组splice方法
      // state.orderData.some((item, i) => {
      //   if (item.order == order) {
      //     console.log(item, i);
      //     state.orderData.splice(i, 1);
      //     return true;
      //   }
      // });
    };
    //单选按钮切换
    const checkStatus = (val) => {
      // console.log(val);
      if (val.label === "5") {
        state.duration = "";
        state.prohibit = true;
        val.service_duration = "15";
      } else {
        state.duration = "60分钟";
        state.prohibit = false;
      }
      state.formList.orderType = val.label;
      state.formList.service_duration = val.service_duration;
    };
    // 选择器
    const checkDuration = (val) => {
      state.formList.service_duration = val;
    };
    // 确认加入
    const joining = () => {
      const formLists = state.orderData.map((item) => {
        return {
          id: item.id,
          orderType: state.formList.orderType,
          service_duration: state.formList.service_duration,
        };
      });
      state.clickloading = true;
      if (formLists == "") {
        ElMessage.error("请加入玩家！");
        state.clickloading = false;
      } else {
        const params = {
          order_id: state.vxOrder,
          players: formLists,
        };
        state.clickDisabled = true;
        service.createOrdersJoin(params).then((res) => {
          state.clickloading = false;
          if (res.data.errcode == 0) {
            ElMessage.success("订单创建成功");
            context.emit("Dialog", "false");
          } else {
            state.clickloading = false;
            ElMessage.error(res.data.msg);
          }
        });
      }
    };
    onMounted(() => {
      getOrderList();
      state.shop_id = localStorage.getItem("shopId");
      handleAdd();
    });
    return {
      ...toRefs(state),
      addPlayer,
      remove,
      joining,
      checkStatus,
      checkDuration,
      form,
      scrollTable,
    };
  },
};
</script>

<style  lang="scss">
// 弹出框
.dialog {
  .dialogTitle {
    padding: 10px !important;
    height: 50px;
    margin-bottom: 38px;
    font-size: 36px;
    color: whitesmoke;
    display: flex;
    justify-content: space-between;
    .dialogTextLeft {
    }
    .dialogTextRight {
      background-color: #52525e;
      width: 156px;
      height: 48px;
      line-height: 48px;
      img {
        width: 24px;
        height: 24px;
        position: relative;
        left: -2px;
      }
      span {
        position: relative;
        top: -6px;
        left: 4px;
      }
    }
  }
  .dialogImg {
    display: flex;
    padding: 0px 15px;
    .QRcode {
      width: 432px;
      border-right: 1px solid #52525e;
      padding-right: 23px;

      .QRimg {
        width: 408px;
        height: 408px;
        border-radius: 4px;
        img {
          width: 100%;
          border-radius: 4px;
          height: 100%;
        }
        .el-loading-spinner .circular {
          margin: 50%;
          display: inline;
          height: var(--el-loading-spinner-size);
          width: var(--el-loading-spinner-size);
          animation: loading-rotate 2s linear infinite;
        }
      }

      .bottomQR {
        font-size: 36px;
        font-weight: 600;
        width: 408px;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        letter-spacing: 10px;
        text-align: center;
        margin-top: 16px;
      }
    }

    .playersTable {
      width: 420px;
      margin-left: 30px;
      .dialogTable {
        width: 364px;
        margin-bottom: 20px;
        .el-table td.el-table__cell div {
          box-sizing: border-box;
        }
        .nameBox {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .nameImg {
            width: 36px;
            height: 36px;
            background-color: seashell;
            border-radius: 4px;
            box-sizing: border-box;
          }
          .nameUser {
            position: relative;
            top: -10px;
            left: 8px;
          }
        }
        .clearOrder {
          color: #8d8d99 !important;
          img {
            width: 12px;
            height: 12px;
            margin-left: 4px;
          }
        }
      }
      .playersBottom {
        padding-top: 16px;
        color: white;
        border-top: 1px solid #52525e;

        .typeBox {
          font-size: 18px;
        }
        .typeInfo {
          display: flex;
          .el-checkbox-group {
            width: 130px !important;
            display: flex;
            justify-content: space-between;
            display: inline-block;
          }
          .el-radio {
            width: 51px;
            color: white !important;
          }
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #6331f0 !important;
            background: #6331f0 !important;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #ffffff !important;
          }
          .timeSelect {
            width: 100px;
            height: 36px;
            box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.10000000149011612);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin: 0 9px;
            font-size: 10px !important;
            .timeSelectInner {
              font-size: 12px !important;
            }
          }
          .determine {
            width: 96px;
            height: 40px;
            background-color: #6331f0;
            box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>